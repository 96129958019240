<template>
  <div id="header-two">
    <section class="bg-marketing d-table w-100"
      style="background: url('images/marketing/marketing-shape.png')"
      id="home">
      <div class="container">
        <div class="row justify-content-center mt-5">
          <div class="col-lg-7 col-md-7 text-center">
            <img src="images/marketing/marketing.png" class="img-fluid" style="max-height: 400px" alt="" />
            <div class="title-heading mt-0 mt-md-5 mt-4 mt-sm-0 pt-2 pt-sm-0">
              <SmartLabel tag="h1" className="heading mb-3" target="title" v-model="content.title"/>
              <SmartLabel tag="p" className="para-desc text-muted" target="subtitle" v-model="content.subtitle"/>
              <div class="mt-4 pt-2">
                <a href="javascript:void(0)" class="btn btn-primary mt-2 mr-2">Get Started</a>
                <router-link to="/page-contact-one" class="btn btn-outline-primary mt-2">
                  <i class="mdi mdi-phone"></i>
                  Contact us
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
  export default {
    name: "HeaderTwo",
  };
</script>